<template>
    <div
        class="tip-tooltip-wrapper"
        ref="tooltip"
        v-bind:class="{
            'tip-tooltip-wrapper-left': position === 'left',
            'tip-tooltip-wrapper-right': position === 'right',
            'tip-tooltip-wrap-content': isLoad === true,
        }"
        v-bind:style="tooltipStyle"
    >
        <div class="tip-tooltip-content">
            {{ value }}

        </div>
        <div v-if="position == 'bottom'" class="tip-tooltip-bottom">
            <div class="tip-tooltip-bottom-before">
                <tooltip-arrow />
            </div>
            <div class="tip-tooltip-bottom-after">
                <tooltip-arrow />
            </div>
        </div>
        <div v-if="position == 'left'" class="tip-tooltip-left">
            <div class="tip-tooltip-left-before">
                <tooltip-arrow />
            </div>
            <div class="tip-tooltip-left-after">
                <tooltip-arrow />
            </div>
        </div>
        <div v-if="position == 'right'" class="tip-tooltip-right">
            <div class="tip-tooltip-right-before">
                <tooltip-arrow />
            </div>
            <div class="tip-tooltip-right-after">
                <tooltip-arrow />
            </div>
        </div>
    </div>
</template>

<script>
import TooltipArrow from '@img/icons/tonscan/tooltip-bottom.svg?vue';

export default {
    props: {
        value: {
            type: String,
        },
        position: {
            type: String,
            default: 'bottom',
        },
    },

    data() {
        return {
            tooltipWidth: 0,
            isLoad: false,
        };
    },

    computed: {
        tooltipStyle() {
            return {
                position: 'absolute',
                ...(this.position === 'left' ? { right: `-${this.tooltipWidth}px` } : { left: `-${this.tooltipWidth}px` }),
            };
        },
    },

    mounted() {
        this.calculateTooltipWidth();
    },

    watch: {
        value: 'calculateTooltipWidth',
    },

    methods: {
        calculateTooltipWidth() {
            this.$nextTick(() => {
                const tooltipElement = this.$refs.tooltip;
                this.tooltipWidth = tooltipElement.clientWidth + 15;
                this.isLoad = true;
            });
        },
    },

    components: {
        TooltipArrow,
    },
};
</script>

<style lang="scss">
.tip-tooltip-wrap-content {
    text-wrap: wrap,
}

.tip-tooltip-wrapper {
    position: absolute;
    transform: translate(-50%, 0%);
    top: -37px;
    left: 50%;
    padding: 12px 14px;
    color: var(--body-text-color);
    border-radius: 12px;
    font-size: 13px;
    background: var(--card-background);
    background-clip: border-box;
    border: 1px solid var(--card-border-color);
    box-shadow: 0 .5rem 1.2rem var(--card-box-shadow-color);
    max-width: 400px;
    z-index: 1001;
}

.tip-tooltip-wrapper-left {
    transform: translate(0%, -50%);
    top: 50%;
    right: 0;
    left: auto;

}

.tip-tooltip-wrapper-right {
    transform: translate(0%, -50%);
    top: 50%;
    right: auto;
    left: 0;
}

.tip-tooltip-bottom-before {
    position: absolute;
    width: 25px;
    height: 6px;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);
    color: var(--card-border-color);
    z-index: 0;
}

.tip-tooltip-bottom-after {
    position: absolute;
    width: 20px;
    height: 10px;
    bottom: -6.1px;
    left: 50%;
    transform: translateX(-50%);
    color: var(--card-background);
    z-index: 5,
}

.tip-tooltip-left-before {
    position: absolute;
    width: 26px;
    height: 6px;
    bottom: 50%;
    top: 50%;
    left: -14px;
    transform: translateY(-50%) rotate(90deg);
    color: var(--card-border-color);
    z-index: 0;
}

.tip-tooltip-left-after {
    position: absolute;
    width: 23px;
    height: 10px;
    bottom: 50%;
    top: 50%;
    left: -13.3px;
    transform: translateY(-50%) rotate(90deg);
    color: var(--card-background);
    z-index: 5;
}
.tip-tooltip-right-before {
    position: absolute;
    width: 26px;
    height: 6px;
    bottom: 50%;
    top: 50%;
    right: -14px;
    transform: translateY(-50%) rotate(-90deg);
    color: var(--card-border-color);
    z-index: 0;
}

.tip-tooltip-right-after {
    position: absolute;
    width: 23px;
    height: 10px;
    bottom: 50%;
    top: 50%;
    right: -13.3px;
    transform: translateY(-50%) rotate(-90deg);
    color: var(--card-background);
    z-index: 5;
}
</style>
