<template>
    <span>
        <template v-if="symbol === '$'">
            {{ symbol }} {{ formattedPrice }}
        </template>
        <template v-else>
            {{ formattedPrice }} {{ symbol }}
        </template>
    </span>
</template>

<script>
export default {
    props: {
        price: {
            type: [Number, String],
            required: true,
        },
        format: {
            type: Boolean,
            default: false,
        },
        symbol: {
            type: String,
            default: '$',
        },
    },
    computed: {
        formattedPrice() {
            const numericPrice = parseFloat(this.price);

            let stringValue;

            if (numericPrice >= 0.1) {
                stringValue = numericPrice.toFixed(2);
            } else if (numericPrice > 0.01) {
                stringValue = numericPrice.toFixed(2);
            } else if (numericPrice > 0) {
                stringValue = numericPrice.toString();

                if (stringValue.includes('e-')) {
                    const parts = stringValue.split('e-');
                    const base = parts[0].replace('.', '');
                    const exponent = parseInt(parts[1], 10);

                    stringValue = '0.' + '0'.repeat(exponent - 1) + base;
                }

                const regex = /0\.(0*)([1-9])/;
                const match = stringValue.match(regex);
                if (match) {
                    const leadingZeros = match[1];
                    const firstNonZero = match[2];
                    stringValue = `0.${leadingZeros}${firstNonZero}`;
                }
            } else {
                return '0.00';
            }

            const parts = stringValue.split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

            return parts.join('.');
        },
    },
};
</script>
