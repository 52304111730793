<template>
    <div
        class="card-status"
        v-bind:class="{
            'card-status__success': traceStatus === true,
            'card-status__failed': traceStatus === false,
        }"
    >
        <div class="card-status-items">
            <div class="card-status-icon">
                <span v-if="traceStatus === undefined" class="skeleton">Unknown</span>
                <template v-else-if="traceStatus">
                    <icon-success />
                </template>
                <template v-else>
                    <icon-failed />
                </template>
            </div>
            <div class="card-status-body">
                <div class="card-status-info">
                    <div class="card-status-title">
                        <span v-if="traceStatus === undefined" class="skeleton">Unknown</span>
                        <template v-else-if="traceStatus">
                            {{ $t('tx.status_success') }}
                        </template>
                        <template v-else>
                            {{ $t('tx.status_failed') }}
                        </template>
                    </div>
                    <div class="card-status-value">
                        <span v-if="traceStatus === undefined" class="skeleton">EQDCHV6...LoH</span>
                        <template v-else-if="traceStatus">
                            <span class="card-status-muted-text">{{ $t('tx.status_success_description') }}</span>
                        </template>
                        <template v-else>
                            <span class="card-status-muted-text">{{ $t('tx.status_failed_description') }}</span>
                        </template>
                    </div>
                </div>
                <div class="card-status-time">
                    <span v-if="traceStatus === undefined" class="skeleton">Unknown</span>
                    <span v-else><ui-datetime v-bind:timestamp="timestamp" v-bind:monthFormatDate="true" /></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconSuccess from '@img/icons/status/success.svg?inline';
import IconFailed from '@img/icons/status/failed.svg?inline';

export default {
    props: {
        isSuccess: Boolean,
        computeExitCode: String,
        actionResultCode: String,
        address: String,
        timestamp: Number,
        traceStatus: Boolean,
    },

    components: {
        IconSuccess, IconFailed,
    },
};
</script>

<style lang="scss">
.card-status {
    border-radius: 12px;
    padding: 16px;
    margin-bottom: 20px;
    &__success {
        background: var(--success-status-transaction-background);
        border: 1px solid var(--success-status-transaction-border);
        .card-status-title {
            color: var(--success-status-transaction-border);
        }

        .card-status-time {
            font-size: 14px;
            color: var(--success-status-transaction-border);
        }

        .card-status-icon {
            color: var(--success-status-transaction-border);
        }

        .card-status-muted-text {
            font-size: 14px;
            color: var(--success-status-transaction-border);
        }
    }
    &__failed {
        background: var(--fail-status-transaction-background);
        border: 1px solid var(--fail-status-transaction-border);
        .card-status-title {
            color: var(--fail-status-transaction-border);
        }

        .card-status-time {
            font-size: 14px;
            color: var(--fail-status-transaction-border);
        }

        .card-status-icon {
            color: var(--fail-status-transaction-border);
        }

        .card-status-muted-text {
            font-size: 14px;
            color: var(--fail-status-transaction-border);
        }
    }
}

.card-status-address {
    width: 150px;
    font-size: 14px;
}

.card-status-items {
    display: flex;
    gap: 20px;
    align-items: center;
}

.card-status-icon {
    width: 43px;
    svg {
        display: block;
    }
}

.card-status-body {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
}

.card-status-title {
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: 500;
}

@media all and (max-width: 480px) {
    .card-status-items {
        flex-direction: column;
    }

    .card-status-body {
        flex-direction: column;
        align-items: center;
    }

    .card-status-address {
        margin: 10px auto 10px auto;
    }

    .card-status-title {
        text-align: center;
    }
}
</style>
