<template>
    <div class="widget">
        <div class="widget-header">
            <div class="widget-title">
                {{$t('indexpage.richest_addresses')}}
            </div>
            <div class="widget-link widget-apps-link">
                <ui-link v-bind:to="{ name: 'whales' }">
                    {{$t('indexpage.all_apps')}}
                </ui-link>
            </div>
        </div>
        <div class="widget-content">
            <div class="widget-wallet">
                <template v-if="whales && whales.length">
                    <rich-wallet-item
                        v-for="(wallet, index) in whales"
                        v-bind:key="`widget-wallet-whale-${index}`"
                        v-bind:address="wallet.account"
                        v-bind:balance="wallet.balance"
                    />
                </template>
                <template v-else>
                    <rich-wallet-skeleton
                        v-for="i in 5"
                        v-bind:key="`widget-wallet-skeleton-${i}`"
                    />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { getTopBalances } from '~/api/toncenterPreview.js';
import RichWalletSkeleton from './RichWalletSkeleton.vue';
import RichWalletItem from './RichWalletItem.vue';

export default {
    data() {
        return {
            whales: [],
        };
    },

    async created() {
        this.whales = undefined;
        this.loadFromLocalStorage();
        const newWhales = await this.loadData();
        if (newWhales && newWhales.length > 0) {
            this.whales = newWhales;
            this.saveToLocalStorage(newWhales);
        }
    },

    methods: {
        async loadData() {
            try {
                const topBalances = await getTopBalances({ limit: 5 });
                return topBalances;
            } catch (error) {
                console.log('Error fetching top balances:', error);
                return [];
            }
        },

        loadFromLocalStorage() {
            const storedWhales = localStorage.getItem('whales');
            if (storedWhales) {
                try {
                    const parsedWhales = JSON.parse(storedWhales);
                    setTimeout(() => {
                        this.whales = parsedWhales;
                    }, 300);
                } catch (error) {
                    console.log('Failed to parse stored whales:', error);
                    this.whales = [];
                }
            }
        },

        saveToLocalStorage(whales) {
            try {
                localStorage.setItem('whales', JSON.stringify(whales));
            } catch (error) {
                console.log('Failed to save whales to localStorage:', error);
            }
        },
    },

    components: {
        RichWalletItem,
        RichWalletSkeleton,
    },
};
</script>

<style lang="scss">

.widget-wallet {
    padding: 20px;
}

.widget-wallet-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 22px;
}

.widget-wallet-item:last-child {
    margin-bottom: 0;
}

.widget-wallet-data {
    display: flex;
    align-items: center;
}

.widget-wallet-balance {
    white-space: nowrap;
    font-weight: 500;
    margin-left: 24px;
}

.widget-wallet-icon {
    width: 18px;
    height: 18px;
    margin-left: 10px;
    fill: var(--body-muted-text-color);
    transform: translateY(-1px);
}

</style>
