<script>
import { MULTIPLIER, getCurrencyFormatter } from '~/helpers.js';

export default {
    props: {
        tonValue: {
            type: [Number, String],
            required: true,
            default: 0,
        },
    },

    render(createElement) {
        const fiatValue = (this.$props.tonValue / MULTIPLIER) * this.$store.state.exchangeRate;

        const formatValue = (value) => {
            if (value >= 0.1) {
                return value.toFixed(2);
            }
            if (value > 0.01) {
                return value.toFixed(2);
            }
            if (value > 0) {
                let stringValue = value.toString();

                if (stringValue.includes('e-')) {
                    const parts = stringValue.split('e-');
                    const base = parts[0].replace('.', '');
                    const exponent = parseInt(parts[1], 10);

                    stringValue = '0.' + '0'.repeat(exponent - 1) + base;
                }

                const regex = /0\.(0*)([1-9])/;
                const match = stringValue.match(regex);
                if (match) {
                    const leadingZeros = match[1];
                    const firstNonZero = match[2];
                    return `0.${leadingZeros}${firstNonZero}`;
                }
                return stringValue;
            }
            return '0.00';
        };

        const customFormattedValue = formatValue(fiatValue);
        const formatter = getCurrencyFormatter(this.$i18n.locale, this.$store.state.exchangeRateCurrency);
        let formattedWithCurrency = formatter(customFormattedValue);

        const parts = formattedWithCurrency.split(/[\.,]/);
        const integerPart = parts[0].replace(/\D/g, '');
        const fractionPart = parts[1] || '';

        const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

        formattedWithCurrency = formattedWithCurrency.replace(integerPart, formattedIntegerPart);

        if (fractionPart) {
            formattedWithCurrency = formattedWithCurrency.replace(fractionPart, `${fractionPart}`);
        }

        return createElement('span', formattedWithCurrency);
    },
};
</script>
