/* eslint camelcase: "off" */
export default {
    header: {
        search_placeholder: 'Поиск по адресу или домену',
        search_domain_error: 'Домен не существует',
        search_address_error: 'Введите адрес TON или домен в зоне .ton',
        links: {
            index: 'Эксплорер',
            blocks: 'Блоки',
            stats: 'Статистика',
            apps: 'Приложения',
            validators: 'Валидаторы',
            whales: 'Топ адресов',
            config: 'Конфиг блокчейна',
        },
    },

    menu: {
        connect_wallet: 'Подключить кошелек',
    },

    indexpage: {
        meta: {
            title: 'TONScan — универсальный обозреватель для блокчейна TON',
            description: 'Вся информация по блокчейну TON в доступном и удобном виде на платформе TONScan. Ищите транзакции, адреса, пулы, номинаторов и многое другое.',
        },
        search_placeholder: 'Поиск приложений, адресов TON, доменов и транзакций...',
        search_domain_error: 'Домен не существует',
        search_address_error: 'Введите адрес TON или домен в зоне .ton',
        ton_price: 'Цена TON',
        market_overview: 'Капитализация',
        top_jettons: 'Топ жетонов',
        apps_and_services: 'Приложения',
        richest_addresses: 'Топ адресов',
        read_more: 'Подробнее',
        explore: 'Исследуйте',
        all_apps: 'Открыть все',
        stakee: {
            header_top: 'Стейкинг TON',
            header_bottom: 'Stakee Bot',
            description: 'Получайте пассивный доход от хранения TON в официальном контракте ликвидного стейкинга с доходностью до 5% годовых.',
            description_muted: 'Здесь используются официальные смарт-контракты, созданные разработчиками TON.',
            stake_ton: 'Вложить TON',
        },
        mobile: {
            menu: {
                staking: 'Стейкинг',
                apps: 'Приложения',
                whales: 'Топ адресов',
                news: 'Новости',
            },
            all_apps: 'Все приложения',
            show_all: 'Показать все',
            show_stats: 'Вся статистика',
        },
    },

    not_found: {
        title: 'Страница не найдена',
        description: 'Не получилось найти страницу, которую вы искали.',
        button: 'Перейти на главную',
    },

    search: {
        category: {
            wallet: 'Кошелёк',
            nft: 'Коллекция NFT',
            pool: 'Номинатор-пул',
            jetton: 'Жетон',
        },
    },

    settings: {
        title: 'Настройки',
        back: 'Назад',
        language: {
            title: 'Язык',
            en: 'English',
            ru: 'Русский',
        },
        tx_source: {
            title: 'Вид транзакций',
            toncenter: 'Стандартный',
            tonapi: 'Альтернативный',
        },
        theme: {
            title: 'Интерфейс',
            light: 'Светлый',
            dark: 'Тёмный',
            auto: 'Авто',
        },
        currency: {
            title: 'Валюта',
            usd: 'Доллар США',
            rub: 'Российский Рубль',
            eur: 'Евро',
            krw: 'Южнокорейская Вона',
            aed: 'Дирхам ОАЭ',
        },
    },

    address: {
        filter: {
            title: 'Фильтры',
        },

        meta: {
            title: 'Адрес {address} в TON · TONScan',
            description: 'Подробная информация об адресе {address}: баланс, транзакции и другая информация. Отслеживайте данные в режиме реального времени.',
        },

        info: {
            address: 'Адрес',
            copy_success: 'Адрес скопирован',
            balance: 'Баланс',
            last_activity: 'Активность',
            state: 'Состояние',
            type_frozen: 'Заморожен',
            type_active: 'Активный',
            type_inactive: 'Неактивный',
            type_suspended: 'Приостановлен',
            contract_type: 'Контракт',
            no_activity: 'Не было транзакций',
        },

        contract_type: {
            wallet: 'Кошелёк',
            nft_collection: 'NFT-коллекция',
            nft_item: 'NFT-итем',
            jetton: 'Жетон',
            jetton_wallet: 'Баланс жетонов',
            nominator_pool: 'Номинатор-пул',
            nominator_pool_single: 'Single Nominator Pool',
            nominator_pool_single_v1_0: 'Single Nominator Pool v1.0',
            nominator_pool_single_v1_1: 'Single Nominator Pool v1.1',
            locker: 'Локер',
            vesting_wallet: 'Вестинг кошелёк',
            unknown: 'Неизвестен',
            domain_name: 'Домен',
            dns_domain: 'Домен .ton',
            nft_sale: 'Продажа NFT',
            nft_offer: 'NFT-оффер',
            subscription: 'Подписка',
            telegram_username: 'Юзернейм в Telegram',
            telegram_number: 'Анонимный номер',
            highload_wallet: 'Высокопроизводительный кошелёк',
            other: 'Прочее',
            sale: {
                sale_info: '{0} за {1}',
                on_auction: 'на аукционе',
                on_sale: 'продаётся',
            },
            storage_provider: {
                name: 'Хранилище',
                rate_template: 'Цена в {period}:',
                rate_value_hour: 'час',
                rate_value_day: 'день',
                rate_value_month: 'месяц',
                rate_value_year: 'год',
                bytes_template: 'за {value}',
                bytes_value_mb: 'МиБ',
                bytes_value_gb: 'ГиБ',
                bytes_value_tb: 'ТиБ',
            },
            subscription_modal_header: 'Контракт подписки',
            nft_sale_modal_header: 'Контракт продажи NFT',
        },

        tab_nfts: 'NFT',
        tab_events: 'История',
        tab_transactions: 'Транзакции',
        tab_contract: 'Контракт',
        tab_tokens: 'Жетоны',

        tx_table: {
            empty: 'История транзакций пуста',
            age: 'Дата',
            from: 'Отправитель',
            to: 'Получатель',
            value: 'Сумма',
            input: 'ВХД',
            output: 'ИСХ',
            log: 'ЛОГ',
            nowhere: 'В никуда',
            external: 'извне',
            address_count: 'пусто | {n} адрес | {n} адреса | {n} адресов',
            show_more: 'ещё',
            swap: 'СВОП',
        },

        all_tx_table: {
            sender: 'Отправитель',
            recipient: 'Получатель',
            title: 'Последние транзакции',
            from: 'из',
            to: 'на',
        },

        nfts: {
            no_nfts: 'Кажется, на этом адресе нет NFT',
            no_collection: 'Не в коллекции',
        },

        tokens: {
            no_tokens: 'На этом адресе нет жетонов',
            table_token_name: 'Жетон',
            table_token_balance: 'Баланс',
            table_token_jetton_wallet: 'Жетон-кошелёк',
            show_empty_tokens: 'Показать {0} пустых кошельков',
            table_token_amount: 'Цена',
        },

        contract: {
            admonition: {
                verified_title: 'Верифицированные исходники',
                verified_text: 'Результат компиляции данных исходных файлов совпадает с байт-кодом контракта в блокчейне. Проверка была выполнена с помощью {verifier}.',
                unverified_title: 'Неизвестный контракт',
                unverified_text: 'У нас нет исходных кодов этого контракта. Загрузить их можно с помощью специального {verifier}.',
                verifier_link_text: 'верификатора',
                inactive_title: 'Неактивный адрес',
                inactive_text: 'В блокчейне нет никаких данных, связанных с этим адресом — либо им никогда не пользовались, либо контракт был по каким-то причинам удалён. В данный момент он просто бесцельно существует в цифровом небытии.',
            },

            tab_raw_source: 'Байт-код',
            tab_raw_data: 'Исходные данные',

            info: 'Информация',
            code: 'Исходный код',
            data: 'Данные',
            source_hex: 'hex',
            source_tvm: 'disassembled',
            source_base64: 'base64',
            source_cells: 'cells',
            info_description: 'На этом адресе лежит смарт-контракт {type}.',
            info_source: 'Его исходный код {link}.',
            source_code: 'доступен на GitHub',
            types: {
                wallet: 'кошелька',
                lockup_wallet: 'защищённого кошелька',
                nft_collection: 'коллекции NFT',
                nft_item: 'NFT-итема',
                elector: 'электора валидаторов',
            },
        },
    },

    tx: {
        meta: {
            title: 'Эксплорер TON :: Транзакция {hash}',
        },
        title: 'Информация о транзакции',
        series: 'Серия транзакций',
        account: 'Аккаунт',
        status: 'Статус',
        status_success: 'Успешно',
        status_failed: 'Ошибка',
        status_success_description: 'Подтвержденная транзакция',
        status_failed_description: 'Неудачная транзакция',
        exit_codes: 'Коды выхода',
        compute_phase: 'фаза compute',
        action_phase: 'фаза action',
        phase_aborted: 'отменено',
        error_icon_tooltip: 'Код ошибки: {0}',
        exit_code_success: 'Успешно',
        exit_code_error: 'Ошибка, код выхода {0}',
        timestamp: 'Время',
        workchain: 'Воркчейн',
        seqno: 'Номер',
        shard: 'Шард',
        fee: 'Комиссия',
        message: 'Комментарий',
        encrypted: 'Зашифровано',
        encrypted_message: 'Зашифрованный комментарий',
        hash: 'Хэш',
        lt: 'LT',
        trace_id: 'Трейс ID',
        timeStart: 'Время',
        description: 'Описание',
        json: 'Данные в формате JSON',
        msgs: 'Сообщения',
        msgs_empty: 'Нет сообщений',
        msgs_count: '{0} входящих, {1} исходящих',
        hash_copy_success: 'Хэш скопирован',
        trace_id_copy_success: 'Трейс ID скопирован',
        lt_copy_success: 'LT скопирован',
        button_transactions: 'Показать транзакции',
        time: 'Время',
        developerData: {
            fees: 'Комиссии',
            totalFee: 'Total Fee',
            gasFee: 'Gas Fee',
            fwdFee: 'Fwd Fee',
            storageFee: 'Storage Fee',
            actionFee: 'Action Fee',
            blockId: 'ID Блока',
            computePhase: 'Compute phase',
            success: 'Success',
            exitCode: 'Exit code',
            skipped: 'Skipped',
            skipReason: 'Skip reason',
            vmSteps: 'VM steps',
            gasUsed: 'Gas used',
            actionPhase: 'Action phase',
            totalActions: 'Total actions',
            skippedActions: 'Skipped actions',
            resultCode: 'Result code',
        },
    },

    block: {
        nav: {
            prev: 'Назад',
            next: 'Вперёд',
            latest: 'Последний',
        },
        masterchain_block_title: 'Блок мастерчейна',
        shardchain_block_title: 'Блок шардчейна',
        shardchain_blocks_title: 'Блоки шардчейна',
        generated_at: 'Дата создания',
        transactions_title: 'Транзакции',
        show_details: 'Показать {0} унылых деталей',
    },

    blocks: {
        last_masterchain_blocks_title: 'Последние блоки мастерчейна',
        last_workchain_blocks_title: 'Последние блоки воркчейна',
        block_time: 'Создание блока',
        tx_per_second: 'Транзакций в секунду',
        transactions: 'Транзакции',
        block: 'Блок',
        workchain: 'Воркчейн',
        hash: 'Хэш',
    },

    nft: {
        collection: {
            meta: {
                title: 'NFT-коллекция {name} :: TON Explorer',
                description: 'Просмотр {count} NFT-итемов из коллекции {name} в эксплорере TON.',
            },
            item_count: 'Пусто | {n} штука | {n} штуки | {n} штук',
            empty_collection_header: 'Пустая коллекция',
            empty_collection_text: 'Кажется, в этой коллекции пока нет NFT. Попробуйте обновить страницу или посмотреть {0}.',
            empty_collection_text_account_link: 'информацию об адресе',
            nonseq_collection_header: 'Особая коллекция',
            nonseq_collection_text: 'Мы старались изо всех сил, но так и не смогли отобразить эту коллекцию. Попробуйте зайти на эту страницу позже или посмотреть {0}.',
            nonseq_collection_text_account_link: 'информацию об адресе',
        },
        item: {
            meta: {
                title: 'NFT-итем {name} (номер {index}) :: Эксплорер TON',
                title_standalone: 'NFT-итем {name} :: Эксплорер TON',
            },
            collection: 'Коллекция',
            no_collection: 'Без коллекции',
            owner: 'Владелец',
            no_owner: 'Нет владельца',
            number: 'Номер',
            metadata_source: 'Хранится в',
            metadata_source_onchain: 'Блокчейне',
            metadata_source_offchain: 'Интернете',
            metadata_view_source: 'открыть',
            attributes: 'Атрибуты',
            no_attributes: 'Отсутствуют',
            recent_tx: 'Недавние транзакции',
            preview: {
                index: 'Номер {index} из {total}',
                error_text: 'Ошибка загрузки NFT',
                error_button: 'Повторить',
            },
        },
        error: {
            redirect_invalid: 'Этот адрес не является NFT',
            error_image: 'Ошибка загрузки изображения',
            retry_phone: 'Нажмите, чтобы перезагрузить',
            retry_pc: 'Нажмите, чтобы перезагрузить',
        },
    },

    jetton: {
        admin_address: 'Владелец',
        admin_address_empty: 'Отсутствует',
        admin_address_empty_explanation: '(владелец отозвал свои права)',
        supply: 'Объём',
        mutable: 'Изменяемый',
        tokens: 'Жетонов',
        metadata_source: 'Хранится в',
        search_wallet: 'Поиск',
        search_wallet_placeholder: 'Вставьте свой адрес',
        recent_tx: 'Недавние транзакции',
        top_holders: 'Холдеры',
        no_holders: 'У этого жетона нет холдеров',
        wallet: {
            title: 'Баланс жетонов',
            owner: 'Владелец',
            jetton: 'Жетон',
        },
        error: {
            redirect_invalid: 'Этот адрес не является жетоном',
        },
    },

    nominator_pool: {
        title: 'Номинатор-пул',
        title_help_link: 'https://tonblockchain.ru/p/063aba1f-a01d-4661-9c61-390b3c1dd8c2',
        button_loading: 'Загрузка...',
        button_disabled: 'Нет свободных слотов',
        button_add_stake: 'Вложить TON',
        button_send_ton: 'Отправить TON',
        help_text_disabled: 'невозможно добавить стейк: нет свободных слотов',
        help_text_add_stake: 'отправьте не менее <b>{0} TON</b> с текстом &laquo;<b>d</b>&raquo; в комментарии',
        validator: 'Валидатор',
        nominator_count: 'Номинаторы',
        nominator_count_value: '{count} из {total}',
        stake: 'Стейк',
        stake_validator: 'Валидатор',
        stake_nominators: 'Номинаторы',
        stake_total: 'Всего',
        start_date: 'Дата начала',
        reward_share: 'Доля наград',
        reward_share_validator: 'Валидатор',
        reward_share_nominators: 'Номинаторы',
        min_stake: 'Мин. стейк',
        min_stake_validator: 'Валидатор',
        min_stake_nominator: 'Номинатор',
        tab_transactions: 'Транзакции',
        tab_nominators: 'Номинаторы',
        nominator_table_empty: 'Нет номинаторов',
        nominator_table_stake: 'Стейк',
        nominator_table_pending: 'В ожидании',
        nominator_table_withdrawal: 'В отзыве',
        tab_votings: 'Голосования',
        voting_table_empty: 'В данный момент нет голосований',
        voting_table_proposal_hash: 'Хэш предложения',
        voting_table_created_at: 'Создано',
        votes: {
            cast_vote: 'Проголосовать',
            vote_list: 'Голоса',
            disclaimer: 'Учитываются голоса только валидатора и номинаторов.',
            vote_list_empty: 'Ещё никто не проголосовал',
            votes_table_supported: 'Голос',
            votes_table_date: 'Дата',
        },
        votes_bar: {
            title: 'Результаты голосования',
            in_favor: 'За',
            against: 'Против',
            abstained: 'Воздержались',
        },
        error: {
            redirect_invalid: 'Этот адрес не является номинатор-пулом',
        },
    },

    single_nominator: {
        pool: 'Single nominator pool',
        owner: 'Владелец',
        title_help_link: 'https://github.com/orbs-network/single-nominator',
        error: {
            redirect_invalid: 'Смарт контракт данного адреса не single nominator',
        },
    },

    vesting: {
        vesting: 'Вестинг',
        address: 'Вестинг адрес',
        owner: 'Владелец',
        total: 'Всего',
        available: 'Доступно',
        locked: 'Заблокировано',
        duration: 'Длительность',
        vesting_duration: 'Продолжительность вестинга',
        cliff_period: 'Отсрочка',
        unlock: 'Разблокировка',
        unlock_text: 'Каждый {period} после отсрочки',
        start: 'Начало вестинга',
        link: 'Открыть на vesting.ton.org',
        title_help_link: 'https://tonblockchain.ru/vesting',
        error: {
            redirect_invalid: 'Смарт контракт данного адреса не vesting',
        },
    },

    locker: {
        title: 'The Locker',
        total_deposits: 'Всего вложено',
        total_rewards: 'Общая сумма вознаграждений',
        storage: 'Хранилище',
        deposits_until: 'Приём депозитов до',
        unlock: 'Разблокировка',
        unlock_text: 'Каждые {period} после вестинга',
        title_help_link: 'https://tonblockchain.ru/locker',
        button_text_disabled: 'Сбор закончен',
        help_text_disabled: 'Приём депозитов окончен: локер заблокирован',
        error: {
            redirect_invalid: 'Смарт контракт данного адреса не locker',
        },
    },

    suspended: {
        header: 'Замороженные адреса',
        description: 'Для обеспечения прозрачности и улучшения токеномики Toncoin, 171 кошелёк ранних майнеров был заморожен путём <a href="https://t.me/ruton/153">голосования валидаторов</a> на 4 года — до 21 февраля 2027 года',
        about: 'Этот и {0} ранних майнеров были заморожены по итогам голосования валидаторов',
        about_address_count: '170 других адресов',
    },

    subscription: {
        contract_description_html: 'Это&nbsp;&ndash; контракт <strong>подписки</strong>. Раз в определённое время он отправляет ТОНы на адрес <strong>бенефициара</strong>, в обмен на доступ к какому-либо сервису (например, каналу Telegram).',
        subscriber: 'Подписчик',
        beneficiary: 'Бенефициар',
        price: 'Цена',
        first_payment_date: 'Создан',
        last_payment_date: 'Посл. платёж',
        subscription_id: 'ID подписки',
        period: {
            monthly: 'в месяц',
        },
    },

    events: {
        title: 'События',
        transferred: 'Отправлено',
        received: 'Получено',
        sent_ton: 'Отправлено',
        received_ton: 'Получено',
        sent_nft: 'Отправка NFT',
        received_nft: 'Получение NFT',
        deploy_nft: 'Создание NFT',
        nft_purchase: 'Покупка NFT',
        sent_jetton: 'Отправлено',
        received_jetton: 'Получено',
        jetton_swap: 'Обмен',
        subscribe: 'Подписка',
        unsubscribe: 'Отмена подписки',
        smart_contract_exec: 'Вызов контракта',
        auction_bid: 'Ставка',
        withdraw_stake: 'Вывод стейка',
        deposit_stake: 'Внесение стейка',
        elections_recover_stake: 'Вывод стейка',
        elections_deposit_stake: 'Внесение стейка',
        withdraw_stake_request: 'Запрос стейка',
        jetton_mint: 'Минт жетона',
        jetton_burn: 'Сжигание жетона',
        contract_deploy: 'Создание контракта',
        external: 'Внешняя',
        jetton_route: 'Route',
        domain_renew: 'Продление домена',
    },

    nft_sale: {
        contract_description_html: 'Это&nbsp;&ndash; контракт <strong>продажи NFT</strong>. Он передаёт NFT новому владельцу как только тот отправит контракту необходимое количество ТОНов.',
        nft_item: 'NFT-итем',
        price: 'Цена',
        is_active: 'Активный?',
        closed_help_text: '(больше не продаётся)',
        purchase: 'Купить',
        marketplace: 'Маркетплейс',
        seller: 'Продавец',
        fees: 'Комиссия',
        marketplace_fee: 'Маркетплейса',
        royalty: 'Создателя NFT',
    },

    common: {
        address: 'Адрес',
        loading: 'Загрузка...',
        load_more: 'Загрузить ещё',
        show_more: 'Показать ещё',
        no_description: 'Нет описания',
        unknown: 'Неизвестно',
        no_data: 'Нет данных',
        empty: 'пусто',
        error: 'Ошибка',
        what_is_this: 'Что это?',
        yes: 'Да',
        no: 'Нет',
        about: 'Информация',
        done: 'Готово',
        total: 'Всего',
        pie: 'Доля',
        raw_metadata: 'Необработанные метаданные',
        nft_item: 'NFT-итем',
        general: 'Общее',
        time: 'Время',
        day: 'День',
        week: 'Неделя',
        two_weeks: 'Две недели',
        month: 'Месяц',
        year: 'Год',
        y: 'г',
        d: 'д',
        h: 'ч',
        days: 'дней | день | {n} дня | {n} дней',
        hours: 'часов | час | {n} часа | {n} часов',
        minutes: 'минут | минута | {n} минуты | {n} минут',
        seconds: 'секунд | секунда | {n} секунды | {n} секунд',
    },

    stats: {
        masterchain_height: 'Высота мастерчейна',
        block_time: 'Создание блока: {time}с',
        transactions_count: 'Количество транзакций',
        transactions_per_second: 'Транзакции отсутствуют | {n} транзакция в секунду | {n} транзакции в секунду | {n} транзакций в секунду',
        circulation: 'Циркуляция',
        total_supply: 'Общее предложение',
        percent_total_supply: '{total}% от общего объема предложения',
        percent_inflation_rate: 'Годовая инфляция 0.6%',
        validators: 'Валидаторы',
        validators_amount: 'Валидаторов',
        validators_election: 'Следующий раунд {n}',
        market_data: 'Рыночные данные',
        price: 'Цена TON',
        capitalization: 'Капитализация',
        trading_volume: 'Объём торгов',
        contract_types: 'Типы контрактов',
        total_addresses: 'всего адресов',
        address_count: 'Количество адресов',
        address: 'Адресов',
        transaction_count: 'Количество транзакций',
        volume: 'Объем',
        user: 'Пользователь',
        service: 'Сервис',
        staking: 'Стейкинг',
        total_staked: 'Общий стейк',
        total_stake: 'Общий стейк',
        frozen_stake: 'Замороженный стейк',
        weight: 'Вес',
        apy: 'Доходность',
        staking_apy: 'Доходность',
        validation_status: 'Статус валидации',
        round_started: 'Начало раунда',
        round_ends: 'Окончание раунда',
        election_id: 'Election ID',
        election: 'Выборы',
        delay: 'Задержка',
        validation: 'Валидация',
        hold: 'Удержание',
        next_round: 'Следующий раунд',
        election_done: 'Готово',
        election_delay_done: 'Готово',
        election_active: 'Активна',
    },

    top_balances: {
        header: 'Топ китов The&nbsp;Open&nbsp;Network',
        subheader: 'Рейтинг самых богатых адресов блокчейна по балансу TON',
        meta: {
            title: 'Топ китов TON :: TONScan',
            description: 'Список китов блокчейна TON — топ самых крупных держателей TON по активному балансу.',
        },
    },

    config: {
        menu: 'Параметры',
        no_value: 'Значение данного параметра не установлено',
        explore_address: 'Обзор адреса',
        blackhole_addr: 'Blackhole address',
        fee_burn_nom: 'Fee burn nom',
        fee_burn_denom: 'Fee burn denom',
        currencies: 'Currencies',
        currency_id: 'Currency id',
        amount: 'Amount',
        version: 'Version',
        capabilities: 'Capabilities',
        normal_params: 'Normal params',
        critical_params: 'Critical params',
        min_tot_rounds: 'Min. total rounds',
        max_tot_rounds: 'Max. total rounds',
        min_wins: 'Min. wins',
        max_losses: 'Max. losses',
        min_store_sec: 'Min. store seconds',
        max_store_sec: 'Max. store seconds',
        bit_price: 'Bit price',
        cell_price: 'Cell price',
        workchains: 'Workchains',
        workchain: 'Workchain',
        enabled_since: 'Enabled since',
        actual_min_split: 'Actual min. split',
        min_split: 'Min. split',
        max_split: 'Max. split',
        basic: 'Basic',
        active: 'Active',
        accept_msgs: 'Accept messages',
        flags: 'Flags',
        zerostate_root_hash: 'Zerostate root hash',
        zerostate_file_hash: 'Zerostate file hash',
        masterchain_block_fee: 'Masterchain block fee',
        basechain_block_fee: 'Basechain block fee',
        validators_elected_for: 'Validators elected for',
        elections_start_before: 'Elections start before',
        elections_end_before: 'Elections end before',
        stake_held_for: 'Stake held for',
        max_validators: 'Max. validators',
        max_main_validators: 'Max. main validators',
        min_validators: 'Min. validators',
        min_stake: 'Min. stake',
        max_stake: 'Max. stake',
        min_total_stake: 'Min. total stake',
        max_stake_factor: 'Max stake factor',
        utime_since: 'Utime since',
        bit_price_ps: 'Price per bit',
        cell_price_ps: 'Price per cell',
        mc_bit_price_ps: 'Masterchain price per bit',
        mc_cell_price_ps: 'Masterchain price per cell',
        gas_limits_prices: 'Gas limit prices',
        special_gas_limit: 'Special gas limit',
        flat_gas_limit: 'Flat gas limit',
        flat_gas_price: 'Flat gas price',
        gas_price: 'Gas price',
        gas_limit: 'Gas limit',
        gas_credit: 'Gas credit',
        block_gas_limit: 'Block gas limit',
        freeze_due_limit: 'Freeze due limit',
        delete_due_limit: 'Delete due limit',
        bytes: 'Bytes',
        gas: 'Gas',
        lt_delta: 'LT delta',
        underload: 'Underload',
        soft_limit: 'Soft limit',
        hard_limit: 'Hard limit',
        msg_forward_prices: 'Message forward prices',
        lump_price: 'Lump price',
        ihr_price_factor: 'IHR price factor',
        first_frac: 'First frac',
        next_frac: 'Next frac',
        new_catchain_ids: 'New catchain ids',
        round_candidates: 'Candidates per round',
        next_candidate_delay_ms: 'Next candidate delay',
        consensus_timeout_ms: 'Consensus timeout',
        fast_attempts: 'Fast attempts',
        attempt_duration: 'Attempt duration',
        catchain_max_deps: 'Catchain max. deps',
        max_block_bytes: 'Max. block bytes',
        max_collated_bytes: 'Max. collated bytes',
        proto_version: 'Proto version',
        catchain_max_blocks_coeff: 'Catchain max. blocks coefficient',
        shuffle_mc_validators: 'Shuffle masterchain validators',
        mc_catchain_lifetime: 'Masterchain catchain lifetime',
        shard_catchain_lifetime: 'Shard catchain lifetime',
        shard_validators_lifetime: 'Shard validators lifetime',
        shard_validators_num: 'Shard validators number',
        suspended_until: 'Suspended until',
        bridge_addr: 'Bridge address',
        oracle_multisig_address: 'Oracle multisig address',
        external_chain_address: 'External chain address',
        prices: 'Prices',
        state_flags: 'State flags',
        bridge_burn_fee: 'Bridge burn fee',
        bridge_mint_fee: 'Bridge mint fee',
        wallet_min_tons_for_storage: 'Min. TON for storage in wallet',
        wallet_gas_consumption: 'Wallet gas consumption',
        minter_min_tons_for_storage: 'Minter min. TON for storage',
        discover_gas_consumption: 'Discover gas consumption',
        param_0: {
            title: 'Адрес конфигурации',
            description: 'Адрес смарт-контракта с конфигурацией блокчейна. Конфигурация хранится в контракте для упрощения её загрузки и изменения во время голосования валидаторов.',
        },
        param_1: {
            title: 'Адрес электора',
            description: 'Адрес смарт-контракта Elector, ответственного за назначение валидаторов, распределение наград и голосование по изменению параметров блокчейна.',
        },
        param_2: {
            title: 'Адрес системы',
            description: 'Адрес смарт-контрата System, от имени которого создаются новые TON и отправляются вознаграждения за проверку блокчейна.',
        },
        param_3: {
            title: 'Сборщик комиссий',
            description: 'Адрес смарт-контракта, собирающего комиссии за транзакции. Если значение параметра не установлено - комиссии отправляются на смарт контракт Elector.',
        },
        param_4: {
            title: 'Корневой контракт DNS',
            description: 'Адрес корневого DNS сети TON.',
        },
        param_5: {
            title: 'Blackhole address',
            description: 'Все TON, отправленные на этот адрес будут уничтожены.',
        },
        param_6: {
            title: 'Новые валюты',
            description: 'Размер комиссии за выпуск для новых валют. На данный момент выпуск новых валют приостановлен.',
        },
        param_7: {
            title: 'Объём валют',
            description: 'Объём каждой из дополнительных валют в обращении. ',
        },
        param_8: {
            title: 'Версия сети',
            description: 'Версия сети и дополнительные возможности, поддерживаемые валидаторами.',
        },
        param_9: {
            title: 'Обязательные параметры',
            description: 'Список обязательных параметров, которые нельзя удалить путём предложения по изменению конфигурации.',
        },
        param_10: {
            title: 'Критические параметры',
            description: 'Список критических параметров TON, изменение которых существенно влияет на сеть, и поэтому для внесения изменений проводится больше раундов голосования.',
        },
        param_11: {
            title: 'Условия внесения предложений',
            description: 'Условия приёма предложений по изменению параметров конфигурации.',
        },
        param_12: {
            title: 'Конфигурация воркчейнов',
            description: 'Конфигурация воркчейнов в блокчейне TON.',
        },
        param_13: {
            title: 'Плата за жалобы',
            description: 'Стоимость подачи жалобы на неправильную работу валидаторов в контракте Elector.',
        },
        param_14: {
            title: 'Награда за блок',
            description: 'Размер награды за создание новых блоков.',
        },
        param_15: {
            title: 'Данные о выборах',
            description: 'Продолжительность различных этапов выборов и работы валидаторов в секундах.',
        },
        param_16: {
            title: 'Количество валидаторов',
            description: 'Параметр определяет минимальное и максимальное количество валидаторов.',
        },
        param_17: {
            title: 'Параметры стейкинга',
            description: 'Конфигурация параметров стейкинга в блокчейне TON. ',
        },
        param_18: {
            title: 'Цена хранения',
            description: 'Стоимость хранения данных в блокчейне TON, для предотвращения спама и поддержки сети.',
        },
        param_20: {
            title: 'Параметры газа',
            description: 'Стоимость вычислений в сети TON. Сложность любых вычислений оценивается в единицах газа.',
        },
        param_21: {
            title: 'Параметры газа в мастерчейне',
            description: 'Стоимость вычислений в сети TON. Сложность любых вычислений оценивается в единицах газа.',
        },
        param_22: {
            title: 'Ограничения для блоков в мастерчейне',
            description: 'Данный параметр устанавливает ограничения, достигнув которые блок считается окончательно созданным.',
        },
        param_23: {
            title: 'Ограничения для блоков в воркчейнах',
            description: 'Данный параметр устанавливает ограничения, достигнув которые блок считается окончательно созданным.',
        },
        param_24: {
            title: 'Цена сообщения в мастерчейне',
            description: 'Стоимости отправки сообщений в основной цепочке блокчейна.',
        },
        param_25: {
            title: 'Цена сообщения в воркчейне',
            description: 'Стоимости отправки сообщений в воркчейнах.',
        },
        param_28: {
            title: 'Catchain конфигурация ',
            description: 'Конфигурация протокола консенсуса Catchain.',
        },
        param_29: {
            title: 'Конфигурация консенсуса',
            description: 'Конфигурация протокола консенсуса над catchain.',
        },
        param_31: {
            title: 'Льготные адреса',
            description: 'Смарт-контракты за работу которых не взимаются комиссии ни за газ, ни за хранение, и в которых можно создавать транзакции tick-tok. В список включены контракты управляющие блокчейном.',
        },
        param_32: {
            title: 'Прошлые валидаторы',
            description: 'Списки валидаторов из предыдущего раунда.',
        },
        param_34: {
            title: 'Текущие валидаторы',
            description: 'Списки валидаторов из текущего раунда.',
        },
        param_36: {
            title: 'Будущие валидаторы',
            description: 'Списки валидаторов из предыдущего раунда.',
        },
        param_40: {
            title: 'Штрафы валидаторов',
            description: 'Сумма штрафа за пропуск и неподтверждение блоков. В случае отсутствия параметра размер стандартного штрафа составляет 101 TON.',
        },
        param_43: {
            title: 'Ограничения размера',
            description: 'Этот параметр относится к различным ограничениям размера и другим характеристикам счетов и сообщений.',
        },
        param_44: {
            title: 'Замороженные адреса',
            description: 'Этот параметр определяет список приостановленных адресов, которые нельзя инициализировать до момента разморозки (suspended_until). ',
        },
        param_71: {
            title: 'Мост ETH для TON',
            description: 'Моста для переноса монет TON в блокчейн Ethereum и обратно.',
        },
        param_72: {
            title: 'Мост BSC для TON',
            description: 'Моста для переноса монет TON в сеть Binance Smart Chain и обратно.',
        },
        param_73: {
            title: 'Мост Polygon для TON',
            description: 'Моста для переноса монет TON в блокчейн Polygon и обратно.',
        },
        param_79: {
            title: 'Мост Ethereum',
            description: 'Мост для обертывания токенов из Ethereum в жетоны на блокчейне TON.',
        },
        param_81: {
            title: 'Мост BSC',
            description: 'Мост для обертывания токенов из Binance Smart Chain в жетоны на блокчейне TON.',
        },
        param_82: {
            title: 'Мост Polygon',
            description: 'Мост для обертывания токенов из Polygon в жетоны на блокчейне TON.',
        },
    },

    error: {
        invalid_address: 'Кажется, это некорректный адрес. Откуда вы его взяли?',
        invalid_tx: 'Кажется, вы перешли по некорректной ссылке на транзакцию. Откуда вы её взяли?',
    },

    apps: {
        title: 'Приложения',
        error_category: 'Категория не найдена',
        go_catalog: 'В каталог',
        banner: {
            title: 'Приложения и Сервисы<br>The Open Network',
            submit_app: 'Предложить',
            learn_more: 'Узнать больше',
            link: 'https://t.me/tonlive',
        },
        categories: {
            all: 'Все',
            all_apps: 'Все',
            wallets: 'Кошельки',
            exchanges: 'Биржи',
            marketplaces: 'Маркетплейсы',
            games: 'Игры',
            staking: 'Стейкинг',
            social: 'Социальные',
            tools: 'Инструменты',
            other: 'Другое',
            dexs: 'DEX',
        },
        app: {
            open_app: 'Открыть',
            category: 'Категория',
            website: 'Ссылка',
            blockchain_address: 'Блокчейн адрес',
            similar_products: 'Похожие приложения',
            nothing_found: 'Ничего не найдено',
            show_more: 'Показать еще',
            error_app: 'Приложение не найдено',
        },
        filters: {
            popular: 'Топ',
            top: 'Топ',
            newest: 'Новые',
        },
        search: 'Искать приложения и сервисы',
        app_card: {
            hot: 'Топ',
            new: 'Новые',
        },
    },

    filter: {
        timeFormat: 'Формат времени',
        timeAbsoluteTitle: 'Абсолютное',
        timeAbsoluteDescription: '25 мая, 09:41',
        timeSmartTitle: 'Умный формат',
        timeSmartDescription: 'Комбинированное время',
        timeUnixTitle: 'Unix-время',
        timeRelativeTitle: 'Относительное',
        timeRelativeDescription: '20 часов назад',
        transactionView: 'Вид транзакций',
        transactionAlternativeTitle: 'Упрощённый',
        transactionAlternativeDescription: 'Транзакции, сгруппированные в события',
        transactionProfessionalTitle: 'Разработчик',
        transactionProfessionalDescription: 'Транзакции в сыром виде',
        switchToUnixTime: 'Испольковать Unix-время',
    },
};
