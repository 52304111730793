<template>
    <section style="overflow-x: auto; width: 100%; scrollbar-width: none">
        <table style="width: 100%;">
            <tr>
                <td>Source</td>
                <td>
                    <ui-address v-if="source" v-bind:address="source"/>
                    <template v-else>empty</template>
                </td>
            </tr>
            <tr>
                <td>Destination</td>
                <td>
                    <ui-address v-if="destination" v-bind:address="destination"/>
                    <template v-else>empty</template>
                </td>
            </tr>
            <tr>
                <td>Value</td>
                <td>{{$ton(value, false)}} TON</td>
            </tr>
            <tr>
                <td>Forward fee</td>
                <td>{{$fee(fwdFee)}} TON</td>
            </tr>
            <tr>
                <td>IHR fee</td>
                <td>{{$fee(ihrFee)}} TON</td>
            </tr>
            <tr>
                <td>Creation LT</td>
                <td v-text="createdLt || 0"/>
            </tr>
            <tr>
                <td>Hash</td>
                <td>
                    <ui-copy-button v-bind:copy="hash">
                        {{ hash }}
                    </ui-copy-button>
                </td>
            </tr>
            <tr v-if="comment">
                <td>Message</td>
                <td>{{comment || 'empty'}}</td>
            </tr>
            <tr v-if="op">
                <td>Operation</td>
                <td>
                    {{ op }}
                    <span v-if="getOpcodeLabel" class="muted">– {{ getOpcodeLabel }}</span>
                </td>
            </tr>
            <tr v-if="createdAt">
                <td>Created Time</td>
                <td>{{ formatTimestamp(createdAt) }}</td>
            </tr>
        </table>
    </section>
</template>

<script>
import { formatTimestamp } from '~/helpers.js';
import opCodesDictionary from '../../../json/traceLabels.json';

export default {
    props: {
        source: String,
        destination: String,
        value: String,
        fwdFee: String,
        ihrFee: String,
        createdLt: String,
        hash: String,
        comment: String,
        op: String,
        createdAt: Number,
    },

    computed: {
        getOpcodeLabel() {
            const opcodeFind = opCodesDictionary[this.op] || null;
            return opcodeFind;
        },
    },

    methods: {
        formatTimestamp,
        opCodesDictionary,
    },
};
</script>
